@media (max-width: $tamanioMobile) {
    @import './menuMobile.scss';

    .slick-arrow {
        display: none !important;
    }

    .img-logo-mobile {
        width: 200px;
    }

    .content-data {
        margin: 10px 0;
        text-align: center;
    }

    .content-servicio {
        margin-bottom: 15px !important;
    }

    .arrow {
        display: none;
    }

    .content-info-parque-interno {
        margin-top: -5% !important;
        border-radius: 6px;
        border: 1px solid #BEBEBE;
    }

    .content-parque {
        h2 {
            font-size: 13px;
        }

        .content-icon {
            img {
                top: -20px;
                border: solid 5px $colorBlanco !important;
                width: 73px !important;
                z-index: 99;
                margin-bottom: -62px !important;
            }
        }
    }

    .px-nth4-left-1 {
        padding-right: 3px !important;
        padding-left: 3px !important;
    }

    .parque-page {

        & h2,
        & h3 {
            font-size: 18px !important;
            text-align: center;
        }
    }

    button.btn.btn-verde {
        width: 100%;
    }

    .content-grilla {
        margin: 10px 0;
        padding: 10px !important;
        background-color: #fafafa;

        .content-link-mapa {
            img {
                margin-top: 10px;
            }
        }
    }
    .content-servicio-mobile.back-green.box {
        vertical-align: middle;
        padding: 15px 10px;
        cursor: pointer;
    }
    p.title-mobile {
        text-transform: uppercase;
        vertical-align: sub;
        display: inline-block;
        font-size: 22px;
    }
}

.content-footer-fix {
    background-color: #95c11f;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .2);
    border-radius: 5px;
    padding: 10px 20px;
    position: fixed;
    bottom: 0;
    width: 100%;

    .b-r-l {
        border-left: solid 1px #fff;
        border-right: solid 1px #fff;
    }

    .col {

        text-align: center;
        font-size: 10px;
        padding: 2px 0;

        p {
            color: #fff;
            margin-top: 2px;
            font-size: 9px;
        }
    }
}