.contact {
    div {

        .contacto-contactenos {
            font-size: 25px;
            text-align: center;
            font-weight: bold;
        }

        .contacto-phone a {
            text-align: center;
            font-size: 42px;
            color: $colorAzul;
            font-weight: bold;
        }

        .contacto-phone a:hover {
            color: $colorVerde;
            text-decoration: none;
        }

        .contacto-siguenos {
            text-align: center;
            font-size: 22px;
            color: $colorAzul;
            font-weight: bold;
        }
    }
}

.iconrrss {
    color: $colorAzul;
    width: 25px;
    padding: 0 2px;
}

.iconrrss:hover {
    color: $colorVerde;
}

.form-control {
    border: solid 1px #f1f1f1;
    color: #627171 !important;
    border-radius: 0;
    font-weight: 300 !important;
}

textarea.form-control {
    height: 150px;
}

.button-contact {
    font-size: 0.8em !important;
    padding: 0 10px !important;
    color: white;
    cursor: pointer;
    border-radius: 4px !important;

    &:hover {
        background-color: #1aa5c6 !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}