.img-logo-footer { 
    width: 200px !important;
}

.footer-contactenos {
    text-align: left;
    font-weight: bold;
}

.footer-phone { 
    margin-top: 10px;
    text-align: left;
    font-size: 30px;
    color: $colorAzul;
    font-weight: bold !important;
}

.footer-container { 

    margin-top: 35px;

    .footer-contact {
        margin-top: 5px;
        cursor: pointer;

        div {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px 0 0;
        }
        img{
            width: 37px;
        }
        :hover {
            .footer-phone {
                color: $colorVerde;
                text-decoration: none !important;
            }
        }
    }

}

.rrss {
    display: inline-block;

    .icon-bottom-rrss {
        color: $colorAzul;
        padding-left: 12px;
    }

    .icon-bottom-rrss:hover {
        color: $colorVerde;
        text-decoration: none !important;
    }

}

.footer-container {
    @media (max-width: $tamanioMobile) {
        &__row {
            margin-bottom: 90px !important;
        }
    }

    &__row {
        border-top: solid #eee 1px;
        padding-top: 30px;
        padding-left: 5px;
    }

    &__socials {
        text-align: left;
        color: $colorAzul;
        padding-top: 25px;
        padding-bottom: 10px;
    }

    .text-necesite {
        font-size: 15px;
        padding-bottom: 5px;
    }

    .list-footer {
        padding: 0;
        list-style: none;
        line-height: 23px;

        .item-footer {
            padding: 2px 0;

            p,
            a {
                font-size: 12px !important;
                color: #212121;
                font-weight: 300;
            }

            .underline {
                text-decoration: underline;
            }
        }

        .item-footer-title {
            font-size: 1.1em;
            font-weight: bold;
            padding-bottom: 14px;
        }
    }

    .post-footer {
        padding-bottom: 10px;

        &__info {
            font-size: 0.8em;
            font-weight: 100;

            &-right {
                text-align: right;
            }
        }
    }
}

.hover-link {
    :hover {
        color: $colorVerde !important;
    }
}

.content-desktop {
    z-index: 9999;
    position: fixed;
    right: 20px;
    bottom: 40px;

    img {
        width: 60px;
    }
}

.content-mobile {
    img {
        width: 28px;
    }
}