.compra-linea {
    padding-top: 40px;
    padding-bottom: 60px;

    .title-green {
        text-align: center;
        background-color: #95C11F;
        display: inline-block;
        width: 100%;
        padding: 0 0 4px 0;
        color: #fff;
        margin-bottom: 4px;
        font-size: 25px;
        font-weight: 400 !important;

        @media (min-width: $tamanioMobile) {
            font-size: 38px !important;
        }

        strong {
            font-weight: bold;
        }
    }

    .back {
        background-color: $colorAzul;
        padding: 0px 7px 1px;
        border-radius: 20px;
        color: #fff;
        position: absolute;
        font-size: 15px;
        cursor: pointer;
    }

    .box {
        margin: 20px;
        padding: 35px 30px 100px;

        h3 {
            text-align: center;
            font-size: 18px;
            max-width: 300px;
            margin: 0 auto 30px;
            font-weight: 400 !important;
        }

        button {
            max-width: 350px;
            display: block;
            margin: 0 auto 15px;

            @media (min-width: $tamanioMobile) {
                text-transform: uppercase;
                font-weight: bold;
                width: 100%;
                border-radius: 1px !important;
                font-size: 20px;
                padding: 10px !important;
            }
        }
    }
}

.box-precios {
    .precio-normal {
        .total {
            color: #f00 !important;
            font-size: 12px;
            font-weight: bold !important;
        }

        .valores {
            text-decoration: line-through;
            font-size: 13px;
            color: #f00;

            .precio {
                color: #f00;
            }
        }
    }

    .precio-web {
        margin-top: 10px;

        .total {
            font-weight: bold !important;
        }

        .valores {
            font-weight: bold !important;
            color: $colorAzul;

            .precio {
                font-weight: bold !important;
            }
        }
    }

    .disclaimer {
        font-size: 13px;
    }
}

.box-product {
    border: solid 1px #f1f1f1;
    padding: 10px;
    margin: 10px 0;



    .content-button {
        border-top: solid 1px #f1f1f1;
        margin-top: 30px;
        padding: 30px;

        button {
            display: inline-block !important;
            padding: 5px 8px !important;
            font-weight: bold;
            margin: 0 auto;
        }
    }

    .item-image {
        width: 100%;
    }

    h2 {
        text-align: left;
        font-weight: bold !important;
        font-size: 18px;
        margin: 10px 0;
    }

    .info-producto {
        border-bottom: solid 1px #f1f1f1;
        padding-bottom: 5px;
    }

    .box-detalle {
        background-color: $colorBlanco;
        min-height: 600px;

        @media (min-width: 767px) {
            background-color: #FAFAFA !important;
        }

        @media (max-width: 767px) {
            min-height: auto;
        }

        &__icon_detalle {

            padding: 6px 5px;

            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                    font-size: 15px;
                    color: #627171;
                }

                li:before {
                    content: "• ";
                    color: $colorAzul;
                    font-size: 20px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.box-product-contratar {
    border: solid 1px #f1f1f1;
    background-color: #fcfcfc;
    padding: 10px;
    margin: 10px;
    position: relative;


    @media (min-width: $tamanioMobile) {
        border: none;
        background-color: #fff;
        padding: 0;
        margin: 0 -15px;
        position: relative;

        .product-contratar-desktop {
            border: solid 1px #f1f1f1;
            background-color: #fcfcfc;
            padding: 10px;
            position: relative;
        }
    }

    .cerrar-plan {
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 18px;
        z-index: 99;
    }

    .item-image {
        width: 70px;
        height: 70px;
        border-radius: 60px;
        margin-top: 15px;

        @media (min-width: $tamanioMobile) {
            width: 100%;
            height: auto;
            border-radius: 0;
            margin-top: 0;
        }
    }

    h2 {
        text-align: left;
        font-weight: bold !important;
        font-size: 18px;
        margin: 10px 0 5px;
    }

    .info-producto {
        padding-bottom: 5px;
    }

    .servicio {
        .title-servicio {
            font-weight: 700 !important;
        }

        li {
            list-style: none;
            font-size: 15px;
            color: #627171;

        }

        li:before {
            content: "• ";
            color: $colorAzul;
            font-size: 20px;
            padding-right: 5px;
        }
    }
}

.box-form-contratar {
    margin-top: 50px;

    h3 {
        text-align: center;
        font-weight: bold !important;
        font-size: 17px;
    }

    .form-contratar {
        form {
            border: solid 1px #f1f1f1;
            padding: 30px;

            label {
                font-size: 14px;
                color: #627171;
                margin: 0;
            }

            .content-button {

                padding: 30px 30px 0 30px;

                button {
                    display: inline-block !important;
                    padding: 5px 8px !important;
                    font-weight: bold;
                    margin: 0 auto;
                }
            }
        }
    }
}

.seleccione-plan {
    background-color: $colorAzul;
    padding: 10px;
    border-radius: 5px;

    @media (min-width: $tamanioMobile) {
        text-align: center;
        border-radius: 2px;

        h2,
        p {
            display: inline-block !important;
            font-size: 28px !important;
            text-align: center !important;
        }

        p {
            margin-left: 3px !important;
        }
    }

    h2 {
        color: #fff !important;
        font-size: 18px;
        margin: 0;
        font-weight: 700 !important;
        text-transform: initial;
    }

    p {
        color: #fff;
        margin: 0;
    }
}

.btn-pink {
    a {
        background-color: #ff0280;
        text-align: center;
        color: #fff;
        vertical-align: -webkit-baseline-middle;
        font-weight: bold !important;
        margin: 5px 0;
        padding: 10px;
        cursor: pointer;
        display: inline-block;
        padding: 5px 0 !important;
        max-width: 70% !important;
        width: 100%;
    }
}

.content-footer-success {
    margin-top: 15px;

    .fondo-green {
        background-color: #95C11F;
        padding: 10px 350px;

        @media (max-width: $tamanioMobile) {
            padding: 10px 20px;
        }

        p {
            color: #fff;
            font-size: 16px;
        }

        .funeraria {
            margin-top: 15px;
            margin-bottom: 10px;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}

.acepta-condicion {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #95C11F;
    }
}