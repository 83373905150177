@import './../../assets/scss/color.scss';

.content_footer_fix {
    background-color: $colorWhite;
    width: 100%;
    position: fixed;
    bottom: -1px;
    background-color: $colorWhite;
    border-top: solid 1px #f1f1f1;

    .col_mb {
        flex: 1 0;
        padding-top: 10px;
        text-align: center;

        p {
            color: $colorGray;
            font-size: 12px;
            margin: 8px 0 5px 0;
        }

        img {
            height: 22px;
            width: auto;
        }
    }
}