.app-mantenimiento {
    height: 100vh;
    background-size: cover !important;
    background-position: center top !important;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    h1 {
        color: #fff;
        font-weight: bold;
        font-size: 60px;
    }

    &__description {
        color: rgba(255,255,255,.75);
        font-weight: 300;
        font-size: 18px;
    }
}

@media (max-width: 560px) {
    .app-mantenimiento {
        h1 {
            font-size: 24px;
        }
    
        &__description {
            font-size: 12px;
            text-align: center;
        }
    }
}