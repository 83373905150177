@import './../../assets/scss/color.scss';

#page-wrap {
    .menu-left {
        padding: 30px;

        .menu-items {
            padding: 5px 0;

            li {
                list-style: none;

                span {
                    margin-right: 15px;
                }

                a {
                    width: 100%;
                    display: inline-block;
                    text-align: left;
                    padding: 15px 0;
                    font-size: 16px;
                    text-transform: none;
                    color: $colorBlack;
                    letter-spacing: 1px;
                }
            }
        }
    }
}