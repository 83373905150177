$colorGreen : #811e6c;//#95C11F;
$colorSimpleGreen : #ECF4D8;
$colorBlue : rgba(0,0,0,1);//#00A5C8;
$colorSimpleBlue : #D9F2F7;
$colorGray : #627171;
$colorSimpleGray : #B7B7B7;
$colorBlack : #000000;
$colorWhite:#FFFFFF;
$colorSimpleMorado : rgba(129, 30, 108,.3);
$colorMorado : #811e6c;

.tx_green{
    color: $colorGreen;
}