.jardines-container {
    position: relative;

    &.jardines-bt {
        margin-bottom: 50px;
    }

    .button {
        text-transform: uppercase;
        border-radius: 3px !important;
        padding: 8px 25px !important;
    }

    h2 {
        display: none;
    }

    .arrow-left {
        position: absolute;
        width: 45px;
        left: -60px;
        top: 40%;
        cursor: pointer;
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }

    .arrow-right {
        width: 45px;
        position: absolute;
        right: -60px;
        top: 40%;
        cursor: pointer;
    }
}

.title-flotante-banner {
    top: 45%;
    position: absolute;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $colorBlanco;
}

.content-jardin-tematico {
    margin: 0 10px !important;

    div {
        .logo-jardin-tematico {
            padding-top: 10px;
        }

        div {
            p {
                text-align: center !important;
                margin-top: 0 !important;
                height: 32px !important;
            }
        }
    }
}

.react-multi-carousel-list.carousel-container.undefined {
    margin: 0 0 50px 0;
}

.react-multi-carousel-dot--active button {
    background: $colorAzul;

}

.react-multi-carousel-dot button {
    border-color: $colorAzul;
}

.react-multi-carousel-dot button {
    background-color: #00a5c8de;
}

.react-multi-carousel-dot button:hover {
    background: $colorAzul;
}


ul.alice-carousel__dots {
    margin: 10px 0 40px;
}

.alice-carousel__dots-item {
    width: 12px !important;
    height: 12px !important;
    background-color: #00a5c8ab !important;
}

li.alice-carousel__dots-item.__active {
    background-color: $colorAzul !important;
}