@import './../../assets/scss/color.scss';

.contentFooter {
    footer {
        margin-top: 35px;
        margin-bottom: 100px;

        p {
            color: $colorGray;
            font-size: 16px;
            line-height: normal;
        }
    }
}