@import './../../assets/scss/color.scss';

.box-method-pay {
    .total {
        border-radius: 20px;
        padding: 20px 0 20px 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.02), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;

        p {
            font-weight: bold !important;
            font-size: 18px;
            text-transform: uppercase;
            color: $colorBlack;
        }
    }

    .checkout {
        .sl-method-pay {
            margin: 20px 0 !important;
            h4 {
                margin: 0;
            }

            img {
                height: 50px !important;
                width: auto !important;
            }
        }
    }
}