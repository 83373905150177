
.tx {
    &._green {
        color: $colorGreen;
    }

    &._blue {
        color: $colorBlue;
    }

    &._black {
        color: $colorBlack;

    }

    &._white {
        color: $colorWhite;
    }

    &._gray {
        color: $colorGray;

    }
}

.bg {
    &._green {
        background-color: $colorGreen;
    }

    &._blue {
        background-color: $colorBlue;
        color: $colorWhite;
    }

    &._black {
        background-color: $colorBlack;

    }

    &._white {
        background-color: $colorWhite;
    }

    &._gray {
        background-color: $colorGray;

    }

}