@import './../../assets/scss/color.scss';

.item-agreement {
    border-radius: 20px;
    padding: 20px 15px 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.02), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: $colorWhite;

    &__img-product {
        border: solid 3px $colorGreen;
    }

    &__content {
        margin-bottom: 15px;

        .title {
            font-weight: bold !important;
            color: $colorGreen !important;
            font-size: 16px;
        }

        p {
            color: $colorSimpleGray;
            font-size: 14px;
            font-weight: 500 !important;
            margin-bottom: 1px;

            .blue {
                color: $colorBlue;
                font-weight: 500;
            }
        }

        .link-agreement {
            color: $colorBlue;
            font-size: 14px;
            text-decoration: underline;
        }

        .aviso {
            color: $colorBlue !important;
            font-weight: bold !important;
            font-size: 16px;
            text-decoration: underline;
        }
    }
}