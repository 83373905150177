.content-servicio-mantencion {
    border: solid 1px #ddd;
    height: 310px;
    border-radius: 3px;
    padding: 40px 10px 20px;
    background-color: #FAFAFA;
    text-align: center;

    img {
        margin-bottom: 10px;
        width: auto;
        height: 115px;
    }

    .text-info-mantencion {
        min-height: 100px;
        margin-top: 5px;
    }
}

.tipo-mantencion {
    .button-cotizar {
        bottom: 0;
        margin-top: 50px !important;
    }
}