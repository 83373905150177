.banner-top {
    position: relative;
    margin-bottom: 50px;

    .sinPadding {
        padding: 0;
    }

    &__title {
        top: 45%;
        position: absolute;
        width: 100%;
        font-size: 2em;
        text-align: center;
        text-transform: uppercase;
        color: white;
    }
}

.btn-flotante-banner {
    position: absolute;
    bottom: 20px;
    margin: 0 auto;
    text-align: center;
    left: 39%;

    a {
        color: $colorBlanco;
        border: none;
        padding: 5px 20px;
        font-size: 20px;
        font-weight: bold;

        &.whatsapp {
            background-color: $colorVerde !important;
        }

        &:hover {
            color: #f1f1f1;
            opacity: 0.9;
        }

        &.contacto {
            background-color: $colorAzul !important;
        }
    }
}

@media (max-width: $tamanioMobile) {
    .btn-flotante-banner {
        position: relative;
        text-align: center;
        margin-bottom: 20px !important;
        bottom: 0;
        left: 0;

        a {
            padding: 5px 10px;

        }
    }
}

.banner-top-fondo {
    width: 100%;
    height: 250px;
    background-position: center center;
    background-size: initial;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 50px;
}