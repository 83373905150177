.nuestras-oficinas {
    padding-bottom: 80px;
}

.btn-descargar {
    img {
        width: 15px !important;
        margin-top: 3px;
    }
}

.oficinas .btn-descargar {
    font-weight: 300 !important;
    font-size: 14px;

    &:hover img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }

}

.tel-link-color {
    color: $colorVerde;
}

.tel-link-color:hover {
    color: #6B9300;
}

.oficinas .btn-descargar {
    background-color: #FAFAFA;
    margin: 5px 0;
    padding: 10px 15px;
    color: #627171;
    border: solid 1px #cccccc;
    text-align: center;
    font-weight: bold;
    text-decoration: none !important;

    a {
        color: #627171 !important;
    }

    img {
        text-align: right;
        float: right;
        width: 24px;
    }
}

.oficinas .btn-descargar:hover {
    background-color: $colorVerde;
    color: $colorBlanco !important;
    text-decoration: none !important;
    cursor: pointer;

    a {
        color: $colorBlanco !important;
    }
}

.title-contenido-desktop {
    margin-bottom: 45px;
}

.d-block {
    padding: 10px !important;
}

.open-list {
    background-color: $colorVerde !important;
}

.title-popup {
    text-transform: capitalize;
}