@import './../../assets/scss/color.scss';

.form_di {
    .errors {
        display: block !important;
        width: 100%;
        font-size: 12px;
        padding-top: 3px;
        color: #f00;
    }
}