.sobre-sendero {
    &__info {
        max-width: 800px;
        margin: auto;
        padding-top: 50px;
        padding-bottom: 75px;
        text-align: center;

        h2 {
            padding-bottom: 20px;
        }

        p {
            margin: auto;
        }
    }

    .boton-postule {
        text-transform: uppercase;
        border-radius: 3px !important;
        padding: 8px 25px !important;

    }

    .content-data {
        padding-left: 30px;
        padding-top: 30px;
    }

    margin-bottom :70px;
}

.content-data {
    margin: 60px 40px;

    .button {
        margin: 0;
        color: $colorBlanco !important;
    }
}