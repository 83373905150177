.title-flotante-banner {
    top: 45%;
    position: absolute;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $colorBlanco;
}

.transparencia-recuadros {
    margin: auto;
    max-width: 1000px;

    &__row {
        padding-top: 40px;
        padding-bottom: 60px;

        .content-servicio {
            border-radius: 4px;
            border: none;
            padding: 0 !important;
            margin: 0px 4px;
            height: 320px;

            .recuadro-title {
                min-height: 80px;
                width: 85%;
                margin: auto;
                border-bottom: solid rgba(255, 255, 255, 0.50) 1px;
                position: relative;

                h2 {
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    transform: translateY(-50%);
                    color: white;
                    text-align: center;
                    font-size: 1.2em;
                    vertical-align: middle !important;
                }
            }

            p {
                margin: 20px 30px;
                font-size: 0.95em;
                line-height: 1.2em;
                text-align: center;
            }


            .more-info-container {
                text-align: center;
                position: absolute;
                bottom: 15px;
                display: block;
                width: 100%;
                margin: auto;
            }

            .more-info {
                margin: auto !important;
                padding: 5px 30px;
                border-radius: 4px;
                text-transform: uppercase;
            }
        }
    }
}
.stock_anfora {
    .bt {
        margin-bottom: 20px !important;

        .title {
            background-color: $colorAzul;
            color: #ffffff;
            padding: 5px 20px;
            font-weight: bold !important;
        }

        .name_product {
            margin: 5px 0;

            p {
                padding-top: 20px;
                font-weight: bold !important;
            }
        }

        img {
            margin: 5px 10px;
            width: 120px;
        }
    }
}