.caso-fallecimiento {

    &__info {
        .text-center {
            margin: auto;
            max-width: 1000px;
            padding: 50px;
        }
    }

    &__items {
        padding-top: 30px;
        padding-bottom: 50px;
    }

    &__lists {
        margin-bottom: 10px;
    }

    &__documentos {
        border-top: solid #ddd 1px;
        padding-top: 50px;

        h2 {
            padding-bottom: 20px;
        }
    }

    &__lista {
        list-style: aliceblue;
        padding-top: 20px;

        li {
            list-style-type: disc;
            font-weight: 300;
            margin-left: 20px;
        }
    }

    &__contacto {
        text-align: center;
        margin-top: 30%;

        div {
            .contacto-contactenos {
                font-size: 20px !important;
            }

            .contacto-phone {
                font-size: 35px !important;

                img {
                    width: 55px;
                }
            }
        }
    }
}