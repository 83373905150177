@import './../../../assets/scss/color.scss';

#outer-container {


    .ct-public {

        &__form {
            padding: 10px 0;
            margin-left: 30px;

            form {
                .content-form {
                    margin-bottom: 35px;
                    min-height: 210px;

                    label {
                        margin-left: 10px;
                    }
                }
            }
        }

        .list-posts {
            .content-items {
                h3 {
                    font-size: 14px;
                    font-weight: bold !important;
                    color: $colorGreen;

                    @media (max-width: 768px) {
                        font-size: 22px;
                        text-align: center;
                    }
                }

                .content-blog {
                    margin: 0;
                    padding-bottom: 10px;

                    .content-blog__title {
                        font-size: 12px;
                        margin-bottom: 5px !important;
                    }
                    .content-blog__excerpt{
                        display: none;
                    }
                    a {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (min-width: 996px) {
    #outer-container {
        .ct-public {
            &__form {
                margin-left: 0px;
            }
        }
    }
}