.title-flotante-banner {
    top: 45%;
    position: absolute;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $colorBlanco;
}

.servicio-sepultura {
    max-width: 1100px;
    margin: auto;

    .info {
        text-align: center;
        padding: 80px 0px;
    }

    .seccion {
        padding-top: 50px;
    }

    .content-servicio {
        border: none;
        border-radius: 4px;
        padding-top: 20px !important;
    }

    /*.cripta {
        width: 50px;
        height: 50px !important;
    }

    .niveles {
        width: 40px;
        height: 80px !important;
    }

    .capacidades {
        width: 40px;
        height: 90px !important;
    }*/

    
    .servicios-container {
        padding-top: 50px;
    }

    .servicio-container {
        padding: 0px 5px;
        margin-bottom: 10px;
        .altura {
            height: 100px;
        }

        .content-servicio {
            position: relative;
            min-height: 330px;
            border-radius: 3px;
            text-align: center;

            .img-servicio {
                height: 90px;
                margin-top: 60px;
                margin-bottom: 15px;

            }

            .text-title {
                margin-bottom: 18px;
                color: white;
                font-weight: bold !important;
                font-size: 1.6em;
                text-transform: uppercase;
            }

            .text-content {
                max-width: 80%;
                margin: auto;
                line-height: 20px;
            }

            .more-info {
                position: absolute;
                padding: 5px 35px !important;
                bottom: 50px;
                margin: auto;
                transform: translateX(-50%);
            }
        }
    }
}

.content-planes {

    h3 {
        min-height: 66px;
        vertical-align: middle;
    }
}

.seccion {
    margin: 0 0 40px;
}