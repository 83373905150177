@import './../../assets/scss/color.scss';

.box-title-seccion {
    margin-bottom: 10px;

    h2 {
        text-align: left;
        color: $colorBlack;
        font-size: 18px;
        font-weight: bold !important;
        text-transform: capitalize;
        margin-bottom: 5px !important;
    }

    p {
        font-weight: 600 !important;
    }
}