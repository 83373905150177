$tamanioMobile : 767px;
$colorAzul:#00A5C8;
$colorVerde:#95C11F;
$colorBlanco:#FFFFFF;
$colorMorado: #811e6c;
$colorNegro: rgba(0,0,0,1);

@import './Canal-Denuncias.scss';
@import './Caso-Fallecimiento.scss';
@import './Contacto.scss';
@import './Contrato-Reglamento.scss';
@import './Crematorio.scss';
@import './Jardines.scss';
@import './NotFound.scss';
@import './Oficinas.scss';
@import './Sepultura.scss';
@import './Sobre-Sendero.scss';
@import './Tipo-Mantencion.scss';
@import './Transparencia.scss';
@import './Header.scss';
@import './Footer.scss';
@import './Responsive.scss';
@import './Json.scss';
@import './BannerTop.scss';
@import './grillaFuneralesDelDia.scss';
@import './grillaUbicacionSepultura.scss';
@import './index.scss';
@import './ParqueParaTodos.scss';
@import './menu.scss';
@import './Ecommerce.scss';

.content-pie {
    color: #627171;
    border: solid 1px #f1f1f1;
    padding: 10px;
    margin-bottom: 25px;

    h5 {
        background-color: #95c11f;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 15px;
        color: #fff;
        font-weight: bold !important;
        cursor: pointer;
    }

    h3 {
        border-bottom: solid 2px #f1f1f1;
        font-size: 22px;
        margin: 20px 0;
        padding-bottom: 10px;
    }

    .row-bottom {
        border-bottom: solid 1px #f1f1f1;
        margin-bottom: 5px;
        padding-bottom: 5px;
    }

    .title {
        font-weight: bold !important;
    }

    table {
        .header {
            background-color: #627171;
        }

        td {
            color: #627171;
        }
    }
}

.btn-pago {
    font-size: 20px !important;
    padding: 0 30px !important;
    margin: 0 0 10px 10px;
    border-radius: 5px !important;
}

.alert-mobile {
    margin-bottom: 0;
    text-align: center;

    a {
        color: #95c11f !important;
    }
}

.d-block {
    padding: 0 !important;
}

.top-mobile {
    margin-top: 0;
}

.container-data {
    margin-top: 158px !important;
}

@media (max-width: 790px) {
    .container-data {
        margin-top: 100px !important;
    }

    .alert-mobile {
        text-align: left !important;
        position: fixed;
        width: 100%;
        z-index: 999;
        top: 0;
        border-radius: 0 !important;

        a {
            margin-left: 30px !important;
        }
    }
}

.flotante {
    position: fixed;
    right: 20px;
    top: 50%;
    z-index: 9999;

    &__icon {
        margin: 4px 0;
        padding: 5px;
        background-color: $colorBlanco;
        text-align: center;

        i {
            background-color: $colorBlanco;
            padding: 4px 2px;
            border-radius: 20px;
            width: 25px;
            height: auto;
        }
    }

    & .blue {
        background-color: $colorAzul;

        i {
            color: $colorAzul;
        }
    }

    & .morado {
        background-color: $colorMorado;

        i {
            color: $colorMorado;
        }
    }

    & .negro {
        background-color: $colorNegro;

        i {
            color: $colorNegro;
        }
    }

    & .green {
        background-color: $colorVerde;

        @media (max-width: $tamanioMobile) {
            &.blue_mobile {
                background-color: $colorAzul !important;

                i {
                    color: $colorAzul;
                }
            }
        }

        i {
            color: $colorVerde;
        }
    }
}

.acceso-firmante {
    .title-acceso-firmante {
        font-weight: 700 !important;
        text-align: center;
        font-size: 20px;
    }

    .btn-confirm {
        button {
            margin-top: 25px;
        }
    }

    .title-form {
        font-weight: bold !important;
        margin-bottom: 30px;
    }

    button {
        width: 100%;
    }

    .box-firmante {
        width: 100%;

        .text-list {
            position: relative;
            background-color: $colorAzul !important;
            text-align: left;
            color: #fff;
            padding: 5px 15px;
            margin: 10px 0 0 0 !important;

            i {
                transform: rotate(90deg);
                text-align: right;
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }

        .open-list {
            i {
                transform: rotate(-90deg);
            }
        }

        #table-blue {
            td {
                border: none;
                background-color: #fafafa;
                font-size: 14px;
                text-align: left;
            }

            input {
                zoom: 2;
            }

            .oculto {
                display: none;
            }

            .ver-mas {
                cursor: pointer;
                font-weight: bold;
                font-size: 14px;

                &:hover {
                    color: #95C11F;
                }
            }

            .gris {
                background-color: #f1f1f1;
            }

            .selected {
                td {
                    background-color: #00a5c845;
                    width: 20%;
                }

                .check {
                    text-align: right;

                    input {
                        zoom: 2;
                    }
                }
            }
        }

        &__title {
            padding: 10px 0;
            background-color: $colorVerde;
            color: $colorBlanco;
        }

        &__data {
            padding: 20px;

            td {
                font-size: 12px;
                padding: 5px 0;
                text-align: left;
                font-weight: 300;
            }


        }
    }

    .finaliza {
        p {
            font-weight: bold !important;
            font-size: 16px;
        }

        a {
            margin-top: 30px;
        }
    }
}

.table-anfora-mb {
    .body {
        td {
            color: #627171;
        }

        img {
            height: 100px;
            width: auto !important;
        }
    }
}

.button {
    background-color: #94C01F;
    color: $colorBlanco;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    border-radius: 3px !important;
    padding: 8px 25px;
}

.detalle {
    margin-top: 30px;

    .circle {
        display: inline-block;
        width: 70px;
        background-color: #95c11f;
        text-align: center;
        border-radius: 40px;
        line-height: 70px;
        color: #fff;

        i {
            font-size: 22px;
        }
    }

    .text-title-detalle {
        margin: 10px 0 !important;

        h3 {
            margin: 0;
            font-size: 24px;
            font-weight: bold !important;
            line-height: 33px;
        }
    }

    .title-detalle {
        background-color: #f2f2f2;
        padding: 15px 75px;
        margin: 10px 0 30px;
        display: inline-block;
        text-align: center;

        h3 {
            margin: 0;
            font-size: 22px;
            text-align: center;
            font-weight: bold !important;
        }
    }

    .back-detalle {
        background-color: #fff !important;
    }
}